import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import '../App.scss';
import { CRUK_LOCATIONS } from './View';
import { useCookies } from 'react-cookie';

export default function Root() {
    const { locationCode } = useParams();
    const [cookies, setCookie] = useCookies(['agentDetails']);

    const [agentName, setAgentName] = useState('');
    const [comments, setComments] = useState('');

    const [modalOpen, setModalOpen] = useState(false);

    const setAgentDetails = () => {
        setCookie('agentDetails', {
            agentName,
            comments,
        });
    };

    useEffect(() => {
        if (cookies.agentDetails) {
            setAgentName(cookies.agentDetails.agentName);
            setComments(cookies.agentDetails.comments);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className='bg-theme_primary flex gap-6 p-4 justify-between items-center border-b-4 border-theme_secondary'>
                <div className='flex gap-6'>
                    <select
                        onChange={(e) => {
                            if (e.currentTarget.value !== 'Choose a Location') {
                                setAgentDetails();
                                window.location.href = `/wp-admin/call-center/${e.currentTarget.value}`;
                            }
                        }}
                        className='bg-white rounded-lg p-2 font-semibold border-none'
                        value={locationCode}
                    >
                        <option value={undefined}>Choose a Location</option>
                        {CRUK_LOCATIONS.map((loc, index) => (
                            <option key={index} value={loc.code}>
                                {loc.name}
                            </option>
                        ))}
                    </select>

                    <input
                        type='text'
                        placeholder='Agent Name'
                        className='bg-white rounded-lg p-2 font-semibold border-none'
                        onChange={(e) => setAgentName(e.target.value)}
                        value={agentName}
                    />

                    <input
                        type='text'
                        placeholder='Comments'
                        className='bg-white rounded-lg p-2 font-semibold border-none'
                        onChange={(e) => setComments(e.target.value)}
                        value={comments}
                    />

                    <button
                        className='bg-theme_secondary text-white font-semibold rounded-lg'
                        onClick={() => {
                            setAgentDetails();
                        }}
                    >
                        Save
                    </button>
                </div>

                <div>
                    <span>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            className='size-8 mr-8 stroke-theme_secondary hover:cursor-pointer'
                            onClick={() => setModalOpen(true)}
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z'
                            />
                        </svg>
                    </span>
                </div>
            </div>

            <Outlet />

            <div
                className={`${
                    modalOpen ? 'absolute' : 'hidden'
                } flex justify-center items-center top-0 bottom-0 left-0 right-0`}
            >
                <div
                    className={`${
                        modalOpen ? 'absolute' : 'hidden'
                    } bg-black opacity-50 top-0 left-0 right-0 bottom-0`}
                ></div>
                <div
                    className={`${
                        modalOpen ? 'fixed' : 'hidden'
                    } bg-white rounded-xl shadow-lg opacity-100 z-10`}
                >
                    <div className='p-12'>
                        <h3>How to use</h3>
                        <ul>
                            <li>Choose a location from the dropdown</li>
                            <li>Enter your agent name and comments</li>
                            <li>Click on Save</li>
                        </ul>

                        <p>
                            After saving, the details you entered will transfer through to
                            Sitelink when creating a reservation
                        </p>

                        <button
                            onClick={() => setModalOpen(false)}
                            className='bg-theme_secondary font-semibold text-lg text-white px-4 py-2 rounded-full'
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
